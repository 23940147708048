import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { Button } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import { Link } from 'react-router-dom';

export default function ListDocuments() {
  return (
    <Box sx={{ width: '100%', maxWidth: 900, bgcolor: 'background.paper' }}>
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Inbox" secondary="asd sdasdas dasda" />
              <ListItemText primary="Inbox" secondary="asd sdasdas dasda" sx={{ display: { xs: 'none', md: 'block'} }} />
              <ListItemText style={{
                textAlign: 'right', maxWidth: 200, 
                // border: '1px solid #000'
                }}>
                <Link to="/sign-document">
                  <Button variant="outlined">Assinar</Button>
                </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider />
        </List>
      </nav>
    </Box>
  );
}