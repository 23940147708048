import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Copyright from '../../resources/components/Copyright';

const theme = createTheme();

const schema = yup.object().shape({
  
  email: yup
  .string()
  .label('Email')
  .trim()
  .email('Não é um email válido')
  // eslint-disable-next-line no-template-curly-in-string
  .min(10,'Mínimo ${min} caractéres')
  // eslint-disable-next-line no-template-curly-in-string
  .max(50,'Máximo ${max} caractéres')
  .nullable(false)
  .required('Campo requerido')
  .typeError('Preencha o campo'),
  // username: yup.string().trim().min(5,'Mínimo 5 caractéres').max(50,'Máximo 50 caractéres').nullable(false).required('Campo requerido').typeError('Preencha o campo'),

});

export default function ForgotPassword() {

  const {register, handleSubmit, formState:{ errors }} = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {

    // const onSubmit = (v) => window.alert(JSON.stringify(v));
    // const handleSubmit = (event) => {
    //   event.preventDefault();
    //   const data = new FormData(event.currentTarget);
    //   // eslint-disable-next-line no-console
    //   console.log({
    //     email: data.get('email'),
    //     password: data.get('password'),
    //   });
    // };

    // check validity
    schema
    .isValid(data)
    .then(function (valid) {
      console.log(valid); // => true
    }).catch(err => {
      console.log(err);      
    });

    schema
    .validate(data).then(valid => {
      console.log(valid);      
    }).catch(function (err) {
      console.log(
        err.name, // => 'ValidationError'
        err.errors, // => ['Deve ser maior que 18']
      );
    });
  
    window.alert(JSON.stringify(data));
    console.log(data);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#000000' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h2" variant="h5">
            Forgot Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  // margin="normal"
                  required
                  fullWidth
                  id="email"
                  {...register("email")}
                  label="E-mail"
                  autoComplete="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  color="inherit"
                  variant="outlined"
                  sx={{ mt: 0, mb: 2, pt: 1, pb: 1, pl: 7, pr:7, }}
                >
                  <div style={styles.buttonEnterPadding}>
                    Reset password
                  </div>
                </Button>
              </Grid>

              <Grid item xs>
                <Link href="/signin" variant="body2">
                  {"Already have an account? Sign in"}
                </Link>
              </Grid>
              <Grid item xs style={{textAlign:'right'}}>
                <Link href="/register" variant="body2">
                  {"Don't have an account?"}
                </Link>
              </Grid>

            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5, mb: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

const styles = {
  buttonEnterPadding: {
    padding: 3,
  },
}