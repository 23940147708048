import React, { useEffect, useState } from "react";
import axios from "axios";
import { usePermission } from "../../resources/context/Permission";

export default function Registration() {

  const {valPermission, setPermission} = usePermission();

  const [usernameReg, setUsernameReg] = useState("");
  const [passwordReg, setPasswordReg] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loginStatus, setLoginStatus] = useState("");
  const [registerStatus, setRegisterStatus] = useState("");

  axios.defaults.withCredentials = true;

  const register = () => {
    console.log('Enviar solicitação de Registro');
    axios.post(`${process.env.REACT_APP_API_AUTH}/register`, { // /register-public
      username: usernameReg,
      password: passwordReg,
    }).then((response) => {
      if(response.data.message){
        setPermission(response.data);
        setRegisterStatus(response.data.message);  
      } else {
        window.alert('Ops');
      }
      console.log('register:post:',response);
    }).catch( err => {
      console.log('NO REGISTER');
      console.log('Error', err);
    });
  };

  const login = () => {
    axios.post(`${process.env.REACT_APP_API_AUTH}/authenticate`, {
      username: username,
      password: password,
    }).then((response) => {
      console.log('response', response);
      console.log('response >>>>>', response.data);
      if (response.data.message) {
        setLoginStatus(response.data.message);
        setPermission(response.data);
      } else {
        setLoginStatus(response.data.username);
        setPermission(response.data);
      }
    }).catch( err => {
      if(String(err).trim() === 'Error: Network Error'){
        window.alert(`Sem conexão.\n${err}`);
      }
      console.log(err)
    });
  };

  function isAuth() {
    console.log('isAuth');
    axios.get(`${process.env.REACT_APP_API_AUTH}/authenticate`)
    .then((response) => {
      if (response.data.isAuth === true) {
        setLoginStatus(response.data.user.username);
        setPermission(response.data);
      }
    }).catch( err => console.log(err) );
  }

  useEffect(isAuth, [setPermission]);

  return (
    <div className="App">
      <div className="registration">
        <h1>Registration</h1>
        <label>Username</label>
        <input
          type="text"
          onChange={(e) => {
            setUsernameReg(e.target.value);
          }}
        />
        <label>Password</label>
        <input
          type="text"
          onChange={(e) => {
            setPasswordReg(e.target.value);
          }}
        />
        <button onClick={register}> Register </button>
      </div>

      <h3>{registerStatus}</h3>

      <div className="login">
        <h1>Login</h1>
        <input
          type="text"
          placeholder="Username..."
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <input
          type="password"
          placeholder="Password..."
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <button onClick={login}> Login </button>
      </div>

      <h3>{loginStatus}</h3>

      {JSON.stringify(valPermission)}

    </div>
  );
}
