import React from "react";
import { Link } from "react-router-dom";
import { usePermission } from "../resources/context/Permission";

function Test() {
  const {valPermission} = usePermission();
  return (
    <div>
      <h1>Title</h1>
      <h3>{valPermission.user && valPermission.user.username}</h3>
      {/* https://github.com/remix-run/react-router/blob/main/docs/getting-started/tutorial.md */}
      <nav
        style={{
          borderBottom: "solid 1px",
          paddingBottom: "1rem"
        }}
      >
        <Link to="/register">Register</Link> |{" "}
        <Link to="/register-old">Register Old</Link> |{" "}
        <Link to="/forgotpassword">Forgot Password</Link> |{" "}
        <Link to="/signin">Sign In</Link> |{" "}
        <Link to="/signout">Sign Out</Link> |{" "}
      </nav>
    </div>
  );
}
export default Test;
