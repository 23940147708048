import React from 'react';
import Exports from './../../resources/libs/fabricjs/Exports';
import Images from './../../resources/libs/fabricjs/Images';
import MiniCanvas from './../../resources/libs/fabricjs/MiniCanvas';
import Paragraph from './../../resources/libs/fabricjs/Paragraph';
import {fabric} from 'fabric';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Alert, AlertTitle, Grid, Slider, Stack } from '@mui/material';
import qrCode from 'qrcode';
import stylesImport from '../styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Resizer from "react-image-file-resizer";
import Fonts from './../../resources/libs/fabricjs/Fonts';
import Brightness6Icon from '@mui/icons-material/Brightness6';
import ContrastIcon from '@mui/icons-material/Contrast';
import UtilsObject from '../../resources/libs/fabricjs/utils/UtilsObject';
import SaveIcon from '@mui/icons-material/Save';
import { Link } from 'react-router-dom';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import CreateIcon from '@mui/icons-material/Create';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import DashPersonalSign from './components/DashPersonalSign';

// http://fabricjs.com/loadfonts
// https://www.npmjs.com/package/fontfaceobserver

// - Add the custom fonts using @import in your CSS
// @import url('https://fonts.googleapis.com/css?family=Pacifico|VT323|Quicksand|Inconsolata');
// - Make an array containing the names of all the custom fonts
// - Load all the custom fonts using a promise or load them by request
// - When the promise is fulfilled, initialize the fabric canvas

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

const Input = styled('input')({
  display: 'none',
});

const signTypes = [
  'photo', 
  'draw',
  'write',
  'phone'
];

export default function ImageEdition() {

  const Exp = new Exports();

  const [canvas, setCanvas] = React.useState(null);
  const [QR, setQR] = React.useState('');
  const [message, setMessage] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const [currentType, setCurrentType] = React.useState('');
  
  const [size, setSize] = React.useState({
    width: 400, 
    height: 200, 
    watherMark: false, 
    name: 'canvas' 
  });

  const loadFonts = async () => {
    return new Promise( async (resolve) => {
      var fontlist = [
        { fileName: 'AmerikaSignatureDemo-Regular.ttf', familyName: 'Amerika Signature Demo' },
        { fileName: 'Autography.otf', familyName: 'Autography' },
      ];
      await Fonts.injectAll(fontlist);
      resolve();
    });

    // fabric.nodeCanvas.registerFont( '/assets/fonts/AmerikaSignatureDemo-Regular.ttf', {
    //   family: 'AmerikaSignatureDemo', weight: 'regular', style: 'normal'
    // });
    // fabric.nodeCanvas.registerFont( '/assets/fonts/Autography.otf', {
    //   family: 'Autography', weight: 'regular', style: 'normal'
    // });
    // fabric.nodeCanvas.registerFont( '/assets/fonts/BabyDandeliaDemo.ttf', {
    //   family: 'BabyDandeliaDemo', weight: 'regular', style: 'normal'
    // });
    // fabric.nodeCanvas.registerFont( '/assets/fonts/Bintang.ttf', {
    //   family: 'Bintang', weight: 'regular', style: 'normal'
    // });
    // fabric.nodeCanvas.registerFont( '/assets/fonts/CreattionDemo.otf', {
    //   family: 'CreattionDemo', weight: 'regular', style: 'normal'
    // });
    // fabric.nodeCanvas.registerFont( '/assets/fonts/DiamondSignature-Regular.ttf', {
    //   family: 'DiamondSignature', weight: 'regular', style: 'normal'
    // });    

  }

  const [signBase64, setSignBase64] = React.useState('');
  const saveSign = async (event) => {
    canvas.set({ backgroundColor: null, stroke: null, strokeWidth: 0, });
    const b = canvas.toDataURL({
      format: 'PNG', 
      width: size.width,
      height: size.height,
    });
    setSignBase64(b);
  }

  const phoneParty = async (event) => {

    setCurrentType('phone');

    canvas.set({
      isDrawingMode: false,
    });

    await clearAll();

    const opt = {
      // errorCorrectionLevel: 'L', 
      quality: 1,
      margin: 1,
      width: 200,
    };

    // qrCode.toCanvas(document.getElementById('canvas-qrcode'), 'http://www.gnnc.com.br', opt,  function (error) {
    //   if (error) console.error(error)
    //   console.log('success!');
    // });

    // With promises
    qrCode.toDataURL('http://www.gnnc.com.br/create-personal-sign ', opt)
    .then(url => {
      console.log(url);
      setQR(url);
    })
    .catch(err => {
      console.error(err)
      setMessage(err);
    })

    setOpen(true);

    // // With async/await
    // const generateQR = async text => {
    //   try {
    //     console.log(await qrCode.toDataURL(text))
    //   } catch (err) {
    //     console.error(err)
    //   }
    // }
    // generateQR('https://gnnc.com.br/ass');
  
  }

  const [textName, setTextName] = React.useState('Seu Nome Aqui');
  const [textFamily, setTextFamily] = React.useState('Autography');

  const write = async () => {

    setCurrentType('write');

    canvas.set({
      isDrawingMode: false,
    });

    await clearAll();
    // await loadFonts();

    setTimeout( () => {

      new Paragraph().addLabel(canvas, {
        text: textName,
        fontFamily: 'Autography',
      }, () => {

        canvas.centerObject(canvas.item(0));
        canvas.item(0).setCoords();
        canvas.calcOffset();
    
        setTimeout( () => {
          canvas.renderAll();
          canvas.requestRenderAll();  
        }, 1000);
    
        setCanvas(canvas);
    
    });

  }, 0);

}

  const draw = async () => {

    setCurrentType('draw');

    await clearAll();

    canvas.set({
      isDrawingMode: true,
      // freeDrawingCursor: 'none',
    });

    canvas.freeDrawingBrush.width = 2;
    canvas.freeDrawingBrush.color = '#000000';

    setCanvas(canvas);

  }

  const erase = () => {

    //  same as `PencilBrush`
    canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
    canvas.isDrawingMode = true;

    //  optional
    canvas.freeDrawingBrush.width = 10;

    //  undo erasing
    canvas.freeDrawingBrush.inverted = true;
    setCanvas(canvas);

  }

  const clearAll = () => {
    return new Promise((resolve, reject) => {
      try {
        canvas.clear();
        canvas._objects.forEach((o, i) => {
          canvas.remove(o);
        });
        fabric.Canvas.prototype.history.reset();
        resolve();
      } catch (error) {
        reject();
      }
  
    });
  }

  const rotate = async (event) => {

    UtilsObject.rotateRight(canvas, canvas.item(0));
    canvas.renderAll();
    canvas.requestRenderAll();
    setCanvas(canvas);

  }

  const [contrast, setContrast] = React.useState(0.5);
  const [brightness, setBrightness] = React.useState(0.1);

  const handleChangeContrast = (event, newValue) => {
    setContrast(newValue);
    renderImage(null, brightness, newValue);
  };
  const handleChangeBrightness = (event, newValue) => {
    setBrightness(newValue);
    renderImage(null, newValue, contrast);
  };

  const renderImage = async (event, bri, con) => {

    function rgbToHex(r, g, b) {
      if (r > 255 || g > 255 || b > 255)
          throw new Error("Invalid color component");
      return ((r << 16) | (g << 8) | b).toString(16);
    }

    try {
      
      canvas.item(0).filters = [];  
      canvas.item(0).applyFilters();

      var c = canvas.getContext('2d');
      var p = c.getImageData(0, 0, 1, 1).data; 
      var hex = "#" + ("000000" + rgbToHex(p[0], p[1], p[2])).slice(-6);
      // window.alert(hex);

      const contrastX = new fabric.Image.filters.Contrast({
        contrast: con, //: 0.5, // 0 to 1.
      })
      const brightnessX = new fabric.Image.filters.Brightness({
        brightness: bri, //: 0, // 0 to 1.
      })
      const removeColorX = new fabric.Image.filters.RemoveColor({
        color: hex,
        distance: 0.2,
        threshold: 0.2,
      });
      const removeWhiteX = new fabric.Image.filters.RemoveColor({
        color: '#ffffff',
        distance: 0.2,
        threshold: 0.2,
      });
      const grey = new fabric.Image.filters.Grayscale({
        mode: 'lightness', // 'average', 'lightness', 'luminosity' 
      });
  
      canvas.item(0).filters.push(grey);
      canvas.item(0).filters.push(contrastX);
      canvas.item(0).filters.push(brightnessX);
      canvas.item(0).filters.push(removeWhiteX);
      // canvas.item(0).filters.push(removeColorX);
  
      canvas.item(0).applyFilters();
      // canvas.item(0).applyFilters(canvas.renderAll.bind(canvas));
  
      // const eff = new Effects();
      // eff.Brightness(canvas, null, {
      //   brightness: 1, //-1 to 1.
      //   object: canvas.item(0),
      // });
      // eff.Contrast(canvas, null, {
      //   contrast: 1, // 0 to 1.
      //   object: canvas.item(0),
      // });
  
      canvas.renderAll();
      canvas.requestRenderAll();
      setCanvas(canvas);

    } catch (error) {
      
    }

  }

  const resizeFile = (file, rotate) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        rotate ? 300 : 500,
        rotate ? 500 : 300,
        "PNG",
        100,
        rotate ? 180+90 : 0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });  
  };

  const isRotate = (file) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          var i = new Image();
          i.src = reader.result;
          i.onload = function () {
            const { width, height } = this;
            console.log(width, height);
            if(width < height) {
              resolve(true);
            } else {
              resolve(false);
            }
          };
        };
        reader.readAsDataURL(file);  
      } catch {
        reject(false);
      }
    });
  };



  const addImageData = async (event) => {

    setCurrentType('photo');

    // document.getElementById('file').files[0].name;
    // document.getElementById('file').files[0].size;
    // document.getElementById('file').files[0].type;

    canvas.set({
      isDrawingMode: false,
    });

    await clearAll();

    const dom  = document.getElementById('imageSelected');
    const sizeBytes = dom.files[0].size;

    if(dom.files.length > 1) {
      window.alert("Selecione apenas uma imagem.");
      return;
    }

    let hasRotate = await isRotate(dom.files[0]);
    const image = await resizeFile(dom.files[0], hasRotate);
    // console.log(image, dom.files[0]);

    new Images().add(canvas, { source: image, }, function(){ 
      renderImage(null, brightness, contrast);
    });

    return;
    // console.log(event.target.files[0]);
    // console.log(dom.files[0]);

    if(dom.files.length > 1) {
      window.alert("Selecione apenas uma imagem.");      
    } else if(sizeBytes < 1031336 && canvas){
      
      let w = size.width - 2;
      let h = size.height - 2;

      new Images().upload(canvas, event, {top: 1, left: 1}, function(){

        const o = canvas.item(0);
        canvas.remove(canvas.item(0));

        o.scaleToWidth(w);
        // o.scaleToHeight(h);

        o.set({
          left: canvas.width/2 - (o.width * o.scaleX)/2,
        });

        // canvas.renderAll();
        // canvas.requestRenderAll();
        canvas.add(o);
        setCanvas(canvas);

        // renderImage();

      });
    } else {
      window.alert("Imagem não pode ser carregada, muito grande. Máximo 1MB.");
    }

  };

  const addImageJPG = () => {

    setCanvas(canvas.remove(canvas.item(0)));

    canvas && new Images().add(canvas, {
      source: "assets/images/sample/filter.jpg",
      top: 10,
      left: 10,
      relativePosition: true,
    });

  };

  const resizePage = () => {
    const v = document.getElementById('asscontainer');
    //console.log(v.offsetWidth);
    const w = v.offsetWidth > 400 ? 400 : v.offsetWidth;
    setSize((prev) => {
      return {
        ...prev,
        width: w,  
      }
    });
  }

  React.useEffect(() => {
    loadFonts();
    resizePage();
    window.addEventListener('resize', resizePage);
    return (() => {
      window.removeEventListener('resize', resizePage);
    })
  },[]);

  return (
    <div style={{ padding: 1 }}>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <center>
            <Typography id="modal-modal-title" variant="h4" component="h2">
              QR Code
            </Typography>
            <img src={QR} alt="QE CODE"/>
            <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
              Aponte a câmera do seu celular para esta imagem (QR) sendo redirecionado para uma página de assinatura.
            </Typography>
          </center>
        </Box>
      </Modal>

      <div id="asscontainer">
        <Grid container spacing={1} style={{ marginTop: 5, marginBottom: 7 }}>
          <Grid item xs={12} sm={6} md={3}>
            <label htmlFor="imageSelected" fullWidth style={{}} >
              <Input accept="image/*" id="imageSelected" multiple type="file" onChange={addImageData} />
              <Button startIcon={<AddAPhotoIcon/>} variant={currentType === 'photo' ? 'contained' : 'outlined'} size="large" type="primary" component="span" fullWidth>
                Selecionar
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>                
            <Button startIcon={<CreateIcon/>} variant={currentType === 'draw' ? 'contained' : 'outlined'} size="large" type="primary" fullWidth style={{}} onClick={draw} >Assinar</Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>                
            <Button startIcon={<TextRotationNoneIcon/>} variant={currentType === 'write' ? 'contained' : 'outlined'} size="large" type="primary" fullWidth style={{}} onClick={write} disabled>Escrever</Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>                
            <Button startIcon={<PhoneIphoneIcon/>} variant={currentType === 'phone' ? 'contained' : 'outlined'} size="large" type="primary" fullWidth style={{}} onClick={phoneParty}>Abrir no Celular</Button>
          </Grid>
        </Grid>

        <div style={{ display: (currentType === 'phone' || currentType === '') ? 'none' : 'block' }}>

          <Grid container 
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{backgroundColor: '#ddd' }}
            >
            <Grid item style={{width: size.width, backgroundColor: '#eee' }}>
              <div style={{width: size.width, height: size.height, border: '1px solid #cccccc'}}>
                {size.width && <MiniCanvas canvas={setCanvas} width={size.width} height={size.height} name={size.name}/>}
              </div>
            </Grid>
          </Grid>

          {currentType === 'photo' && (
            <Grid container sx={{ mt: 2, mb: 1 }}>
              <Grid item xs={12} sm={4}>
                <Grid container>
                  <Grid item xs={6} sx={{ pb: 1, pl: 1 }}>
                    <Button variant='outlined' color='error' onClick={clearAll} startIcon={<ContrastIcon/>} fullWidth>Apagar</Button>
                  </Grid>
                  <Grid item xs={6} sx={{ pb: 1, pl: 1 }}>
                    <Button variant='outlined' onClick={rotate} fullWidth>Rotacionar</Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack spacing={2} direction="row" sx={{ mb: 1, ml: 2 }} alignItems="center">
                  <Brightness6Icon sx={{ mr: 0 }} />
                  <Typography variant='body2' sx={{ pr: 2 }} >Brilhoss</Typography>
                  <Slider aria-label="Brilho" value={brightness} onChange={handleChangeBrightness} min={0} max={1} step={0.1} />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack spacing={2} direction="row" sx={{ mb: 1, ml: 2 }} alignItems="center">
                  <ContrastIcon sx={{ mr: 0 }} />
                  <Typography variant='body2' sx={{ pr: 2 }} >Contraste</Typography>
                  <Slider aria-label="Contraste" value={contrast} onChange={handleChangeContrast} min={0} max={1} step={0.1} />
                </Stack>
              </Grid>
            </Grid>
          )}

          <Alert variant='standard' color='info'>
            Você pode <strong>mover, aumentar, girar, escrever e outras opções</strong> para melhorar sua assinatura.
          </Alert>

        </div>

        {currentType === 'phone' && (
          <>
            <Alert variant='standard' color='info'>
              <AlertTitle>Assinatura via Celular</AlertTitle>
              <h3>Você tem duas opções:</h3>
              <ul>
                <li>Assinatura através do seu celular</li>
                <li>Fotografar sua assinatura pelo celular</li>
              </ul>
            </Alert>
          </>
        )}

        <div style={styles.buttons}>
          <Button variant="contained" size="large" type="primary" startIcon={<SaveIcon/>} sx={{ mr: 1 }} disabled={ Boolean(currentType === '' || currentType === 'phone') } onClick={saveSign}>Salvar assinatura</Button>
          <Link to="/personal-sign">
            <Button variant="outlined" size="large" type="primary" sx={{ mr: 1 }}>Voltar</Button>
          </Link>
        </div>

        {signBase64}

      </div>      
    </div>
  );
} 

const styles = {
  ...stylesImport,
  img: {
    margin: '10px 0 10px 10px',
    border: '1px dotted #444444',
  },
  blocks: {
    width: 352,
    margin: '3px 0',
    fontSize: 12,
  }
}