module.exports = {
  stylesheet: null,

  injectAll: function (fonts) {
		return new Promise((resolve) => {
			if (this.stylesheet === null) {
				this.stylesheet = this.generateStyleSheet();
			}	
			fonts.forEach(this.insertFont.bind(this));
			resolve();
		});
  },

  insertFont: function (font) {
    var rule = this.getFontRule(font);
    this.stylesheet.insertRule(rule, 0);
  },

  getFontRule: function (font) {
    var fontFaceStr = '@font-face { font-family: "' + font.familyName + '"; src: url(/assets/fonts/' + font.fileName + "); }";
    return fontFaceStr;
  },

  generateStyleSheet: function () {
    var style = document.createElement("style");

    style.appendChild(document.createTextNode("")); // webkit hack
    document.head.appendChild(style);

    return style.sheet;
  },
};

// usage
// var fontlist = [
//   { fileName: 'CustomFont.tff', familyName: 'Custom Font SC' },
//   { fileName: 'CustomFont-Two.tff', familyName: 'Custom Font Two SC' }
// ];
// fonts.injectAll(fontlist);

// fabric.nodeCanvas.registerFont(__dirname + '/test/fixtures/Ubuntu-Regular.ttf', {
//   family: 'Ubuntu', weight: 'regular', style: 'normal'
// });

// fabric.util.clearFabricFontCache();
// Clear char widths cache for the given font family or all the cache if no fontFamily is specified.
// Use it if you know you are loading fonts in a lazy way and you are not waiting for custom fonts to load
// properly when adding text objects to the canvas. If a text object is added when its own font is not loaded yet,
// you will get wrong measurement and so wrong bounding boxes. After the font cache is cleared, either change the
// textObject text content or call initDimensions() to trigger a recalculation
