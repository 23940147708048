import React from "react";
import stylesImport from './../styles';
import Cookies from 'universal-cookie';
import { Alert, AlertTitle, Button, Container } from "@mui/material";
import ListDocuments from "./forms/ListDocuments";
import DashDocumentSign from "./components/DashDocumentSign";
import { Link } from "react-router-dom";

const cookies = new Cookies();

export default function Welcome(props) {

  const setStagePage = () => {
    props.setStagePage && props.setStagePage('next'); // terms // next
  }

  const removeAllCookies = () => {
    cookies.remove('acceptTerms', {path: '/'});
    cookies.remove('geo', {path: '/'});
  }

  return (
    <Container maxWidth="md">

      <h2>Contratos</h2>
      <h3>Assistente de contratos on-line</h3>

      <div>
        <DashDocumentSign/>
      </div>

      <div style={styles.containerPaper}>

        <Link to="/personal-sign" className="removeTextDecoration">
          <Alert variant="filled" color="warning" sx={{ mb: 1 }}>
            Você não registrou sua assinatura no sistema. Clique aqui
          </Alert>
        </Link>

        <div>
          <ListDocuments/>
        </div>

        {/* <div style={styles.buttons}>
          <Link to="/sign-document">
            <Button variant="contained" size="large" type="primary" onClick={setStagePage} sx={{ mr: 1 }}>Continuar</Button>            
          </Link>
          <Link to="/personal-sign">
            <Button variant="outlined" size="large" type="primary" sx={{ mr: 1 }}>Assinatura</Button>
          </Link>
        </div> */}

      </div>
      <div style={{fontSize:9, color: '#DDD', textAlign: 'center'}} onDoubleClick={removeAllCookies}>
        Remover all cookies
      </div>
    </Container>
  );
}

const styles = {
  ...stylesImport,
  colorIcons: '#005F4E',
}