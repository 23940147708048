import React from 'react';
import { Link } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
// import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Icon from '@ant-design/icons';
import './MenuLeft.scss';

export default function MenuLeft() {

  const [collapsed, setColl] = React.useState(true);
  const [toggled, setTogg] = React.useState(false);

  // Another menu SAMPLE
  // https://reactjsexample.com/react-side-nav-component/
  
  const HeartSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M4 22h14a2 2 0 002-2V7.5L14.5 2H6a2 2 0 00-2 2v4"></path>
      <path d="M14 2v6h6"></path>
      <path d="M3 15l2 2 4-4"></path>
    </svg>
  );
  
  const HeartIcon = props => <Icon component={HeartSvg} {...props} />;  

  return (
    <ProSidebar style={styles.ProSidebar} collapsed={collapsed} toggled={toggled}
    onMouseOver={() => setColl(false)}
    onBlur={() => setColl(true)}
    onMouseLeave={() => setColl(true)}
    >
      <SidebarHeader style={styles.SidebarHeader}>
        <div style={{ width: 300 }}>
          <SupervisedUserCircleIcon style={{ fontSize: 30, marginRight: 10, marginLeft: 3, float: 'left' }} />
          <div style={{ paddingTop: 5, display: collapsed ? 'none' : 'block' }}>
            DayByDay <strong>Student</strong>
          </div>
        </div>
      </SidebarHeader>
      <SidebarContent>
      <Menu iconShape="square">
        <MenuItem icon={<DashboardIcon />}>
          Dashboard
          <Link to="/" />
        </MenuItem>
        <MenuItem icon={<HeartIcon style={{ fontSize: 35, textAlign: 'center' }} />}>
          Contratos
          <Link to="/sign-documents" />
        </MenuItem>
      </Menu>
      </SidebarContent>
      <SidebarFooter>
        <Menu iconShape="square">
          <MenuItem icon={<LogoutIcon />}>
            Sign Out
            <Link to="/signout" />
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  );
}

const styles = {
  ProSidebar: {
    // fontFamily: 'Roboto',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999,
  },
  SidebarHeader: {
    padding: 20,
    color: 'white',
  }
}