import './App.css';
import React, {useEffect} from "react";
import axios from "axios";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import Main from "./views/main/Main";
import RegisterOld from "./views/auth/RegisterOld";
import { usePermission } from "./resources/context/Permission";
import "./App.css";
import Test from "./views/Test";
import Register from "./views/auth/Register";
import SignIn from "./views/auth/SignIn";
import ForgotPassword from "./views/auth/ForgotPassword";
import PrivateRoute from "./routers/PrivateRoute";
import Signed from "./views/auth/Signed";
import SignOut from "./views/auth/SignOut";
import { isAuthenticate } from "./views/auth/authenticate/authenticate";
import Checkout from './views/auth/checkout/Checkout';
import MenuTop from "./views/layout/MenuTop";
import CreatePersonalSign from './views/personalSign/CreatePersonalSign';
import PersonalSign from './views/personalSign/PersonalSign';
import SignPdf from './views/documentSign/SignPdf';
import Welcome from './views/documentSign/Welcome';
import NavigateRouter from './resources/components/Navigate/NavigateRouter';
import ViewPdf from './views/documentSign/ViewPdf';

axios.defaults.withCredentials = true; 

function App() {

  const {valPermission, setPermission} = usePermission();
  const [wainting, setWaiting] = React.useState(true);
  const {isAuth} = valPermission;

  const [hash, setHash] = React.useState(false);
  function getHashValue(key) {
    var matches = window.location.hash.match(new RegExp(key+'=([^&]*)'));
    return matches ? matches[1] : null;
  }

  useEffect(() => {
    if(window.location.hash) {
      // https://www.gnnc.com.br/sign/#go=/signin
      setHash(getHashValue('go') || false);
      setTimeout(() => {
        setHash(false);
      }, 10000);
      console.log(getHashValue('go'), '!!!');
    } else {
      // No hash is found
    }
  }, []);

  function EnterApp () {
    return (
      <Routes>

        {/* 
        // import { useHistory } from "react-router-dom"
        // let history = useHistory()
        // history.push("/home") 
        */}

        {/* {hash && <Route path="/" element={<Navigate to={`${hash}`}/>} />} */}

        <Route path="./sign" element={<h1>Olá Muando 1</h1>} />
        <Route path="sign" element={<h1>Olá Muando 2</h1>} />
        <Route path="/sign" element={<h1>Olá Muando 3</h1>} />

        <Route path="./sign/" element={<h1>Olá Muando 4</h1>} />
        <Route path="sign/" element={<Test/>} />
        <Route path="/sign/" element={<h1>Olá Muando 6</h1>} />

        <Route path="./sign/signin" element={<h1>Olá Muando signin 1</h1>} />
        <Route path="sign/signin" element={<h1>Olá Muando signin 2</h1>} />
        <Route path="/sign/signin" element={<h1>Olá Muando signin 3</h1>} />

        <Route path="./sign/signin/" element={<h1>Olá Muando signin 1b</h1>} />
        <Route path="sign/signin/" element={<h1>Olá Muando signin 2b</h1>} />
        <Route path="/sign/signin/" element={<h1>Olá Muando signin 3b</h1>} />

        <Route path="/" element={<>Welcome</>} />
        <Route path="/" exact element={<>Welcome2</>} />

        {/* <Route path="/" element={<PrivateRoute auth={valPermission} element={Test}/>} /> */}
        <Route path="/sign-documents" element={<PrivateRoute auth={valPermission} element={Welcome}/>} />
        <Route path="/sign-document" element={<SignPdf/>} />
        <Route path="/view-document" element={<ViewPdf/>} />
        <Route path="/personal-sign" element={<PrivateRoute auth={valPermission} element={PersonalSign}/>} />
        <Route path="/create-personal-sign" element={<CreatePersonalSign/>} />

        <Route path="/register" element={<Register />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signed" element={<Signed/>} />
        <Route path="/signout" element={<SignOut/>} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/register-old" element={<RegisterOld />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/reload" element={<Navigate to={'/'} />} />

        {/* <Route path="/documents-sign" element={<DocumentSign />} /> */}

        <Route
          path="*"
          element={
            <main style={styles.nothingHere}>
              <p>There's nothing here!</p>
              <h1 style={styles.pageNotFound}>404. Page not found</h1>
            </main>
          }
        />

      </Routes>
    )
  }

  // import { useHistory } from "react-router-dom"
  // let history = useHistory()
  // history.push("/home")

  useEffect(() => {
    isAuthenticate().then((data) => {
      setPermission(data);
    }).catch((err) => {
      setPermission({});
    }).finally(() => {
      setWaiting(false);  
    });
  }, [setPermission]);

  // https://github.com/remix-run/react-router/blob/main/docs/getting-started/tutorial.md

  return (
    <React.Fragment>
      <BrowserRouter>
        {isAuth && <MenuTop/>}
        <section style={ isAuth ? styles.container : {} }>
          {wainting || (<EnterApp/>)}
        </section>
        {/* {hash && <Navigate to={`${hash}`} replace={true} />} */}
      </BrowserRouter>
    </React.Fragment>
  );
}

const styles = {
  container: {
    paddingTop: 30,
    paddingBottom: 50,
    marginTop: 0,
    marginLeft: 100,
  },
  nothingHere: {
    padding: 30,
  },
  pageNotFound: {
    margin: 0,
  },
}

export default App;
