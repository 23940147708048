import React from 'react';
import { Link, Typography } from "@mui/material";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props} style={{fontSize:10}}>
      {'Copyright © '} 
      {new Date().getFullYear()}
      {` `}
      <Link color="inherit" href="https://www.gnnc.com.br/">
        Natan Cabral
      </Link>
    </Typography>
  );
}
export default Copyright;