import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { usePermission } from '../../resources/context/Permission';
import { useNavigate } from "react-router-dom";

const theme = createTheme();

export default function SignOut(props) {

  const {setPermission} = usePermission();
  const navigate = useNavigate();

  function out() {
    axios.get(`${process.env.REACT_APP_API_AUTH}/destroy-session`)
    .then((data) => {
      setPermission(data);
      setTimeout(() => navigate('/signin'), 1000);
    });
  }

  useEffect(out, [navigate, setPermission]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h3>
            Waiting...
          </h3>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
