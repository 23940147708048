import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import PermissionProvider from './resources/context/Permission';
// import * as serviceWorker from './serviceWorker';
import * as roc from "react-offline-cache";

ReactDOM.render(
  <PermissionProvider>
    <App />
  </PermissionProvider>, 
  document.getElementById("root")
);

roc.unregister(); // or roc.unregister(); to remove
// console.log('online:', roc.isOnline());