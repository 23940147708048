import React from "react";
import stylesImport from '../styles';
import Cookies from 'universal-cookie';
import { Alert, Button, ButtonGroup, Container } from "@mui/material";
import DashPersonalSign from "./components/DashPersonalSign";
import ListSign from "./form/ListSign";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';

const cookies = new Cookies();

export default function PersonalSign(props) {

  return (
    <Container maxWidth="md">
      <h2>Assinatura & Rúbrica</h2>
      <h3>Marcações em contratos</h3>
      
      <div>
        <DashPersonalSign/>
      </div>

      <div style={styles.containerPaper}>

        <Link to="/create-personal-sign" className="removeTextDecoration">
          <Alert variant="filled" color="warning" sx={{ mb: 1 }}>
            Registre sua assinatura. Clique aqui
          </Alert>
        </Link>

        <Link to="/create-personal-sign" className="removeTextDecoration">
          <Alert variant="filled" color="warning" sx={{ mb: 1 }}>
            Registre sua rública. Clique aqui
          </Alert>
        </Link>

        <div>
          <ListSign/>
        </div>
        <div style={styles.buttons}>

          {/* <Link to="/create-personal-sign">
            <Button variant="contained" size="large" type="primary" sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, }} startIcon={<AddIcon/>}>Assinatura</Button>
          </Link>
          <Link to="/create-personal-sign">
            <Button variant="contained" size="large" type="primary" sx={{ mr: 1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, marginLeft: '-1px', }} startIcon={<AddIcon/>}>Rúbrica</Button>
          </Link> */}

          <Link to="/sign-documents">
            <Button variant="outlined" size="large" type="primary" sx={{ mr: 1 }}>Contratos</Button>
          </Link>
        </div>
      </div>
    </Container>
  );
}

const styles = {
  ...stylesImport,
  colorIcons: '#005F4E',
}