import React, { createContext, useContext, useState } from "react";

export const PermissionContext = createContext();

export default function PermissionProvider({ children }) {

  // Configurações globais
  // Variaveis utilizadas e compartilhadas pelo sistema inteiro

  const [valPermission, setPermission] = useState({});

  return (
    <PermissionContext.Provider value={{ valPermission, setPermission }}>
      {children}
    </PermissionContext.Provider>
  );
}

// created a personal hook
// this call context inside file
export function usePermission(_) {
  const context = useContext( PermissionContext );
  //if(!context) throw new Error('usePermission need privider. usePermission must be used within a PermissionProvider');
  const { valPermission, setPermission } = context;
  return { valPermission, setPermission };
}