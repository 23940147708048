import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  background: '#ffffff',
  border: '1px solid #eee',
}));

// https://mui.com/components/grid/

export default function DashPersonalSign() {
  return (
    <Box sx={{ width: '100%', marginBottom: '20px' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={4}>
          <Item style={{backgroundColor: '#ffffff'}}>
            <h3>Assinatura <CheckCircleOutlineIcon style={{color: 'green'}}/></h3>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item style={{backgroundColor: '#ffffff'}}>
            <h3>Rúbrica <CheckCircleOutlineIcon style={{color: 'green'}}/></h3>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item style={{backgroundColor: '#ffffff'}}>
            <h3>Validação <CheckCircleOutlineIcon style={{color: 'green'}}/></h3>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}