import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../../resources/components/Copyright';
import axios from 'axios';
import { usePermission } from '../../resources/context/Permission';
import { Link } from '@mui/material';
import { useNavigate } from "react-router-dom";

const theme = createTheme();

export default function Signed(props) {

  const {setPermission} = usePermission();
  const navigate = useNavigate();

  function home() {
    navigate('/');
  }

  function signOut() {
    axios.get(`${process.env.REACT_APP_API_AUTH}/destroy-session`)
    .then((data) => {
      setPermission(data);
      setTimeout(() => navigate(props.to || '/signin'), 1000);
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
          {/* <CssBaseline /> */}
          <Box
          sx={{
            marginTop: 5,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#000000' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h2" variant="h5">
            Signed
          </Typography>

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              color="inherit"
              variant="outlined"
              sx={{ mt: 2, mb: 2, pt: 1, pb: 1, pl: 7, pr:7, }}
              onClick={home}
            >
              <div style={styles.buttonEnterPadding}>
                Home
              </div>
            </Button>
            <div style={{textAlign: 'center'}}>
            <Link 
              component="button"
              variant="body2"
              underline="none" 
              onClick={signOut}
              >
              Sign out
            </Link>
            </div>
          </Grid>

        </Box>
        <Copyright sx={{ mt: 5, mb: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

const styles = {
  buttonEnterPadding: {
    padding: 3,
  },
}