import React, {useState, useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Copyright from '../../resources/components/Copyright';
import axios from 'axios';
import { usePermission } from '../../resources/context/Permission';
import { Alert } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Signed from './Signed';
import LinearDeterminate from '../../resources/components/LinearDeterminate';

const theme = createTheme();

const schema = yup.object().shape({
  
  // email: yup
  // .string()
  // .label('E-mail')
  // .trim()
  // .email('Não é um email válido')
  // .min(10,'Mínimo ${min} caractéres')
  // .max(50,'Máximo ${max} caractéres')
  // .nullable(false)
  // .required('Campo requerido')
  // .typeError('Preencha o campo'),

  username: yup
  .string()
  .label('Username')
  .trim()
  // eslint-disable-next-line no-template-curly-in-string
  .min(5, 'Must be ${min} characters')
  // .max(50, 'Máximo ${max} caractéres')
  .nullable(false)
  .required('Campo requerido')
  .typeError('Preencha o campo'),
  
  password: yup
  .string()
  .label('Password')
  .trim()
  .nullable(false)
  // eslint-disable-next-line no-template-curly-in-string
  .min(6,'Must be ${min} characters')
  // .max(15,'Máximo ${max} caractéres')
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&,.;:()])[A-Za-z\d@$!%*#?&,.;:)(^~/\\}{}]{6,}$/,
  //   "Must be 6 characters.  1 Uppercase, 1 Lowercase, 1 Number and 1 special case character (@$!%*#?&,.;)"
  // )
  .nullable(false)
  .required('Campo requerido')
  .typeError('Preencha o campo'),

  // passwordConfirmation: yup.string()
  // .oneOf([yup.ref('password'), null], 'Passwords must match')

});

export default function SignIn(props) {

  const {valPermission, setPermission} = usePermission();
  const [busy, setBusy] = useState(false);
  const [signed, setSigned] = useState();
  const [serverStatus, setServerStatus] = useState('');
  const navigate = useNavigate();

  const {register, handleSubmit, formState:{ errors }} = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {

    // const onSubmit = (v) => window.alert(JSON.stringify(v));
    // const handleSubmit = (event) => {
    //   event.preventDefault();
    //   const data = new FormData(event.currentTarget);
    //   // eslint-disable-next-line no-console
    //   console.log({
    //     email: data.get('email'),
    //     password: data.get('password'),
    //   });
    // };

    // check validity
    schema
    .isValid(data)
    .then(function (valid) {
      if(valid === true) authenticate(data);
    }).catch(err => {
      console.log(err);      
    });
  
  }

  const authenticate = (data) => {
    setBusy(true);
    axios.post(`${process.env.REACT_APP_API_AUTH}/authenticate`, data)
    .then((response) => {      
      // console.log('response', response);
      if (response.data.message) {
        setServerStatus(response.data.message);
        setPermission(response.data);
      } else {
        setServerStatus(''); // allowed
        setPermission(response.data);
        navigate('/', {});
      }
    }).catch( err => {
      if(String(err).trim() === 'Error: Network Error'){
        setServerStatus(`Sem conexão.\n${err}`);
      }
      // console.log(err)
    }).finally(() => {
      setBusy(false);
    })
  };

  // function isAuth() {
  //   console.log('isAuth');
  //   axios.get("http://localhost:5555/api/authenticate")
  //   .then((response) => {
  //     if (response.data.isAuth === true) {
  //       navigate('/', {});
  //     }
  //   }).catch( err => console.log(err) );
  // }

  // useEffect(isAuth, []);

  useEffect(() => {
    setSigned(valPermission.isAuth);
    // if(valPermission.isAuth === true) {
    //   navigate('/signed');
    // }
  }, [valPermission])

  return (
    <React.Fragment>
      {signed ? 
        <Signed/> : (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            {/* <CssBaseline /> */}
            <Box
              sx={{
                marginTop: 5,
                marginBottom: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >

              <Avatar sx={{ m: 1, bgcolor: '#000000' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h2" variant="h5">
                Sign in
              </Typography>

              {serverStatus && <Alert severity="warning" sx={{ mt: 2, }}>{serverStatus}</Alert>}
              {/* {JSON.stringify(valPermission)} */}

              <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  {/* <Grid item xs={12}>
                    <TextField
                      // margin="normal"
                      required
                      fullWidth
                      id="email"
                      {...register("email")}
                      label="E-mail"
                      autoComplete="email"
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                      autoFocus
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="username"
                      {...register("username")}
                      label="Username"
                      autoComplete="username"
                      error={Boolean(errors.username)}
                      helperText={errors.username?.message}
                      disabled={busy}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      // margin="normal"
                      required
                      fullWidth
                      id="password"
                      {...register("password")}
                      label="Password"
                      type="password"
                      error={Boolean(errors.password)}
                      helperText={errors.password?.message}
                      disabled={busy}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      color="inherit"
                      variant="outlined"
                      sx={{ mt: 0, mb: 2, pt: 1, pb: 1, pl: 7, pr:7, }}
                      disabled={busy}
                    >
                      <div style={styles.buttonEnterPadding}>
                        Enter
                      </div>
                    </Button>
                    {busy ? <LinearDeterminate/> : null}
                  </Grid>

                  <Grid item xs>
                    <Link href="/forgotpassword" variant="body2">
                      {"Forgot password?"}
                    </Link>
                  </Grid>
                  <Grid item xs style={{textAlign:'right'}}>
                    <Link href="/register" variant="body2">
                      {"Don't have an account?"}
                    </Link>
                  </Grid>
                  
                </Grid>
              </Box>
            </Box>
            <Copyright sx={{ mt: 5, mb: 5 }} />
          </Container>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
}

const styles = {
  buttonEnterPadding: {
    padding: 3,
  },
}