import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useForm,} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Copyright from '../../resources/components/Copyright';
import { usePermission } from '../../resources/context/Permission.js';
import Signed from './Signed';
import LinearDeterminate from '../../resources/components/LinearDeterminate';

const schema = yup.object().shape({

  fullname: yup
  .string()
  .label('Nome completo')
  .trim()
  // eslint-disable-next-line no-template-curly-in-string
  .min(10,'Mínimo ${min} caractéres')
  // eslint-disable-next-line no-template-curly-in-string
  .max(50,'Máximo ${max} caractéres')
  .nullable(false)
  .required('Campo requerido')
  .typeError('Preencha o campo'),

  // username: yup.string().trim().min(5,'Mínimo 5 caractéres').max(50,'Máximo 50 caractéres').nullable(false).required('Campo requerido').typeError('Preencha o campo'),

  email: yup
  .string()
  .label('Email Confirm')
  .trim()
  .email('Não é um email válido')
  // eslint-disable-next-line no-template-curly-in-string
  .min(10,'Mínimo ${min} caractéres')
  // eslint-disable-next-line no-template-curly-in-string
  .max(50,'Máximo ${max} caractéres')
  .nullable(false)
  .required('Campo requerido')
  .typeError('Preencha o campo'),
  
  phone: yup
  .string()
  .label('Your phone')  
  .matches(/^((\\+[1-9]{1,2}[ \\-]*)|(\\([0-9]{4,5}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{2,4}?$/, 'Phone number is not valid')
  // eslint-disable-next-line no-template-curly-in-string
  .min(10,'Must be ${min} characters')
  // eslint-disable-next-line no-template-curly-in-string
  .max(15,'Máximo ${max} caractéres')
  .nullable(false)
  .required('Campo requerido')
  .typeError('Preencha o campo'),

  password: yup
  .string()
  .label('Password')
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&,.;:()])[A-Za-z\d@$!%*#?&,.;:)(^~/\\}{}]{6,}$/,
    "Must be 6 characters. 1 Uppercase, 1 Lowercase, 1 Number and 1 special case character (@$!%*#?&,.;)"
  )
  .trim()
  // eslint-disable-next-line no-template-curly-in-string
  .min(6,'Must be ${min} characters')
  // eslint-disable-next-line no-template-curly-in-string
  .max(15,'Máximo ${max} caractéres')
  .nullable(false)
  .required('Campo requerido')
  .typeError('Preencha o campo'),

  passwordconfirmation: yup
  .string()
  .oneOf([yup.ref('password'), null], 'Passwords must match'),

})

const theme = createTheme();

export default function Register() {

  const {valPermission} = usePermission();
  const [busy, setBusy] = useState(false);
  const [signed, setSigned] = useState();
  const {register, handleSubmit, formState:{ errors }} = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {

    // console.log(watch(["fullname", 'password'])); // watch input value by passing the name of it => { username }
    // const onSubmit = (v) => window.alert(JSON.stringify(v));
    // const handleSubmit = (event) => {
    //   event.preventDefault();
    //   const data = new FormData(event.currentTarget);
    //   // eslint-disable-next-line no-console
    //   console.log({
    //     email: data.get('email'),
    //     password: data.get('password'),
    //   });
    // };

    // check validity
    schema
    .isValid(data)
    .then(function (valid) {
      console.log(valid); // => true
    }).catch(err => {
      console.log(err);      
    });

    schema
    .validate(data).then(valid => {
      console.log(valid);      
    }).catch(function (err) {
      console.log(
        err.name, // => 'ValidationError'
        err.errors, // => ['Deve ser maior que 18']
      );
    });
  
    window.alert(JSON.stringify(data));
    console.log(data);
  }

  useEffect(() => {
    setSigned(valPermission.isAuth);
    // if(valPermission.isAuth === true) {
    //   navigate('/signed');
    // }
  }, [valPermission])
  
  return (
    <React.Fragment>
    {signed ? 
      <Signed to="/register"/> : (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          {/* <CssBaseline /> */}
          <Box
            sx={{
              marginTop: 5,
              marginBottom: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'black' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h2" variant="h5">
              Register
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="given-name"
                    {...register("fullname")}
                    required
                    fullWidth
                    id="fullname"
                    label="Full Name"

                    error={Boolean(errors.fullname)}
                    helperText={errors.fullname?.message}
                    disabled={busy}
                    autoFocus

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    {...register("email")}
                    autoComplete="email"

                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    disabled={busy}

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="phone"
                    label="Phone"
                    {...register("phone")} 
                    autoComplete="phone"

                    error={Boolean(errors.phone)}
                    helperText={errors.phone?.message}
                    disabled={busy}

                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    {...register("password")}
                    label="Password"
                    type="password"
                    id="password"

                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                    disabled={busy}

                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    {...register("passwordconfirmation", {required: true, minLength: 6, maxLength: 15})}
                    label="Password confirm"
                    type="password"
                    id="passwordconfirmation"

                    error={Boolean(errors.passwordconfirmation)}
                    helperText={errors.passwordconfirmation?.message}
                    disabled={busy}

                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                    label="Acceptance of Terms and Conditions"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                color="inherit"
                variant="outlined"
                sx={{ mt: 2, mb: 2, pt: 1, pb: 1, pl: 7, pr:7, }}
                disabled={busy}
              >
                <div style={styles.buttonEnterPadding}>
                  Register
                </div>
              </Button>
              {busy ? <LinearDeterminate/> : null}
              {/* <Grid container justifyContent="flex-end"> */}
              <Grid item xs>
                <Link href="/signin" variant="body2">
                  {"Already have an account? Sign in"}
                </Link>
              </Grid>
              {/* </Grid> */}
            </Box>
          </Box>
          <Copyright sx={{ mt: 5, mb: 5 }} />
        </Container>
      </ThemeProvider>        
      )}
    </React.Fragment>
  );
}

const styles = {
  buttonEnterPadding: {
    padding: 3,
  },
}