import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MenuAppBar from './MenuAppBar';
import MenuLeft from './MenuLeft';
import { usePermission } from '../../resources/context/Permission';

export default function MenuTop(props) {
  const {valPermission} = usePermission();
  const [user, setUser] = React.useState();
  React.useEffect(() => {
    // console.log(props.auth.user, valPermission.user);
    setUser(valPermission.user);
  }, [props, valPermission]);
  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar style={styles.toolbar}>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, paddingLeft: '90px' }}>
            DayByDay &nbsp; 
            <strong> Student </strong> 
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none',}}}></Box>
          <Box sx={{ flexGrow: 1 }} />
          {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}></Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}></Box> */}
          <Box style={{ paddingRight: 15 }}>
            {user?.email}
          </Box>
          <Box>
            <strong>
              {user?.username}              
            </strong>
          </Box>
          <Box>
            <MenuAppBar/>
          </Box>
        </Toolbar>
      </Box>
      {/* <section style={ styles.container }>
        {props.children}
      </section> */}
      <MenuLeft/>
    </React.Fragment>
  );
}

const styles = {
  toolbar: { 
    minHeight: 35, 
    padding: '2px 10px', 
    backgroundColor:'#fff', 
    fontSize: '0.9rem',
  },
}