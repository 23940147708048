import React from "react";
import UtilsEvents from "./utils/UtilsEvents";
import UtilsCanvas from "./utils/UtilsCanvas";
import { fabric } from "fabric";

const PIXEL_TO_MILLIMETER = 0.2645833333;
// const MILLIMETER_TO_PIXEL = 3.7795275591;

export default class Exports extends React.Component {

  state = {
    properties: [
      // personal
      "_controlsVisibility", // ???
      "isFabricCanvas",
      "typeName",
      "key",
      "name",
      "responsive", // to elements like shadow, follow other elements dynamically
      "clipPathObject", // temporary test (back)
      // radius
      "rx",
      "ry",
      // font
      "fonts",
      "fontFileName",
      "fontFileSize",
      // document, pattern, pallete, fill & stroke
      "document", // to avenuz report, tecnical, ficha tecnica 
      // "pattern",
      "filters",
      // fabricjs
      "evented",
      "hasControl",
      "hasBorders",
      "hasRotatingPoint",
      "selectable",
      "objectCaching",
      "preserveObjectStacking",
      "absolutePositioned",
      // lock
      "lockMovementX",
      "lockMovementY",
      "lockRotation",
      "lockScalingX",
      "lockScalingY",
      "lockUniScaling",
      "hoverCursor",
      // allow images
      "crossOrigin",
    ]
  }

  getProperties = () => {
    return this.state.properties;
  }

  // https://stackoverflow.com/questions/45984263/fabricjs-clipping-and-svg-export

  /**
   * Promisse
   * @param {canvas} c 
   * @returns Promisse
   */
   toJson = (c) => {
    if ( !c.hasOwnProperty('clipPath') || c.clipPath === null || c.clipPath === undefined) {
      window.alert("Define material size on millimeters");
      return;
    }

    // init class
    const uc = new UtilsCanvas();
    // save angle zero
    uc.rotateReset( c );
    // zoom to 1:1
    uc.zoomReset( c ); 
    // Transforma o stage do canvas no tamanho do CLIP
    const {width,height} = c.clipPath.getBoundingRect();
    // block resize and events
    c.exporting = true;
    // set dimensions    
    c.setWidth(width);
    c.setHeight(height);
    // center content
    uc.center( c );
    
    return new Promise((resolve, reject) => {
      try {
        const json = JSON.stringify(c.toJSON(this.state.properties));
        // allow resize and events
        c.exporting = false;
        UtilsEvents.window.dispatch('resize',{})
        // window..dispatchEvent(new CustomEvent(event, { detail: data }));
        // window.dispatch('resize');
        resolve(json);
      } catch (error) {
        // allow resize and events
        c.exporting = false;
        UtilsEvents.window.dispatch('resize',{})
        reject(error);
      }
    });
  };

  keyDownControlSave = ( event, callback ) => {
    if(
        (event.which === 83 && (event.ctrlKey || event.metaKey)) ||
        (event.keyCode === 83 && (navigator.platform.match("Mac") ? event.metaKey : event.ctrlKey))
      ) {
      event.preventDefault();
      if( callback !== undefined ){
        callback();
      }
    };
  };

  toSVG = ( c, object ) => {

    // init class
    const uc = new UtilsCanvas();
    // save angle zero
    uc.rotateReset( c );
    // zoom to 1:1
    c.setViewportTransform([1,0,0,1,0,0]); 
    // Transforma o stage do canvas no tamanho do CLIP
    const {width,height} = c.clipPath.getBoundingRect();
    const {backgroundColor} = c;
    // block resize and events
    c.exporting = true;
    // set dimensions    
    c.setWidth(width);
    c.setHeight(height);
    // transparent
    c.set({ backgroundColor: null, stroke: null, strokeWidth: 0, });
    // center content
    uc.center( c );
    
    return new Promise(( resolve, reject )=>{

      const element = object || c;

      if( !element ){
        reject( 'Element no found!' );
        // done 
        c.set({ backgroundColor: backgroundColor });
        c.exporting = false;
        UtilsEvents.window.dispatch('resize',{})        
      }

      try {

        const svg = element.toSVG({
          // suppressPreamble: true,
          // viewBox: {
          //     x: 80,
          //     y: 80,
          //     width: 250,
          //     height: 250
          // },
        });
        
        // remove FULL MEMORY
        // const svgUri = 'data:image/svg+xml;utf8,' + encodeURIComponent(svg);
  
        resolve({
          svg: svg,
          //svgUri: svgUri, 
        });

        // done 
        c.set({ backgroundColor: backgroundColor });
        c.exporting = false;
        UtilsEvents.window.dispatch('resize',{})

  
      } catch (error) {
        
        reject( error );

        // done 
        c.set({ backgroundColor: backgroundColor });
        c.exporting = false;
        UtilsEvents.window.dispatch('resize',{})

      }

      // // or 
      // // http://fabricjs.com/docs/fabric.c.html#toSVG
      // c.toSVG();
      // // c.toSVG(optionsopt, reviveropt);
      // c.toSVG({
      //   suppressPreamble: true,
      //   viewBox: {
      //       x: 80,
      //       y: 80,
      //       width: 250,
      //       height: 250
      //   }});
      //   //only object
      //   // http://fabricjs.com/docs/fabric.Object.html#toSVG
      //   object.toSVG();

    })
  }

  // save big files (client-side)
  //https://github.com/eligrey/FileSaver.js

  //svg to pdf
  //https://alafr.github.io/SVG-to-PDFKit/

  // anexar o SVG no PDF:
  //https://pdfkit.org/docs/guide.pdf#%5B%7B%22num%22%3A268%2C%22gen%22%3A0%7D%2C%7B%22name%22%3A%22Fit%22%7D%5D

  // article
  //https://threewill.com/create-a-pdf-using-an-svg-file-in-react/

  //https://pdfkit.org/demo/browser.html
  //https://pdfkit.org/docs/guide.pdf
  //https://pdfkit.org/
  //https://alafr.github.io/SVG-to-PDFKit/examples/demo.htm
  //https://runkit.com/alafr/5a1377ff160182001232a91d

  // technical report
  toSVGTechnicalReport = ( c, callback ) => {
  
    // const sc = new fabric.StaticCanvas();
    const sc = new fabric.Canvas();

    this.toJson( c ).then( json => {

      // import to a new canva
      sc.loadFromJSON( json, () => {

        // remove rect resize
        // sc.remove(sc.item(0));

        // WORKING... maybe pattern.fill.hex or pallete.fill.hex
        sc._objects.map( el => el.fill = typeof el.fill === 'string' ? el.fill : '#00000055' );

        // const { clipPath } = c;

        // new Shapes().addRectangle( sc, {
        //   width: 200,
        //   height: clipPath.height,
        //   left: -210,
        //   top: -1,
        //   fill: '#ffffff55',
        //   stroke: '#000000',
        //   strokeWidth: 1,
        //   relativePosition: true,
        // });

        const technicalData = "!Texto Tecnico asdo aspodpaskdoa sdkoas dasodpkasop dkopasd oakdpoaksodpajs odias jdoasidaiosjdi | Texto Tecnico :\nTexto Técnico | Texto Técnico\nTexto Técnico | Texto Técnico\nTexto Técnico | Texto Técnico\nTexto Técnico | Texto Técnico\nTexto Técnico | Texto Técnico\nTexto Técnico | Texto Técnico\nTexto Técnico | Texto Técnico\nTexto Técnico | Texto Técnico\nTexto Técnico | Texto Técnico";
        
        sc.add( new fabric.Textbox( technicalData, {
          width: 200,
          left: UtilsCanvas.setPositionLeft( sc, -210 ),
          top: UtilsCanvas.setPositionLeft( sc, 10 ),
          fontSize: 9,
        }));
        
        this.toSVG( sc ).then(({svg})=>{
          const { clipPath } = c;
          const values = {
            find: `width="${ clipPath.width }" height="${ clipPath.height }" `,
            replace: `width="${ Number(clipPath.width * PIXEL_TO_MILLIMETER).toFixed(4) }mm" height="${ Number(clipPath.height * PIXEL_TO_MILLIMETER).toFixed(4) }mm" `,
            fabric: `Created with Fabric.js `,
            avenuz: `Created with AVENUZ (Natan Cabral|www.gnnc.com.br) `,
          };
          const svgToMM = String(svg).replace( values.find, values.replace ).replace( values.fabric, values.avenuz );
          callback && callback({data:svgToMM});
        });

      });
  
    });
  }


  toDomImage = ( c ) => {
    // https://ourcodeworld.com/articles/read/38/how-to-capture-an-image-from-a-dom-element-with-javascript
  }

  toImageDownload = (c, settings) => {

    const {width: cropWidth, height: cropHeight, top: cropTop, left: cropLeft, format, quality, multiplier, centralize} = settings || {};

    var stream = c.createPNGStream(); //createJPEGStream
    stream.on('data', function(chunk) {
      // response.write(chunk);
      console.log(chunk);
    });
    stream.on('end', function() {
      // response.end();
      console.log('done!');
    });

    if (!window.localStorage) {
      window.alert("This function is not supported by your browser.");
      return;
    }

    // init class
    const uc = new UtilsCanvas();
    // save angle zero
    uc.rotateReset( c );
    // zoom to 1:1
    c.setViewportTransform([1,0,0,1,0,0]); 
    // Transforma o stage do canvas no tamanho do CLIP
    const {clipPath} = c;
    const {width, height} = (clipPath && c.clipPath.getBoundingRect()) || c;
    const {backgroundColor} = c;
    // block resize and events
    c.exporting = true;
    // set dimensions    
    c.setWidth(width);
    c.setHeight(height);
    // transparent
    c.set({ backgroundColor: null, stroke: null, strokeWidth: 0, });
    // center content
    centralize && uc.center( c );

    try {

      const dataURL = c.toDataURL({
        enableRetinaScaling: true,
        // format
        format: format || 'png', // png | jpg
        quality: quality || 1, // only jpg
        // scale
        multiplier: multiplier || 1, // scale, default 1
        // cropped
        width: cropWidth || width,
        height: cropHeight || height,
        left: cropLeft || 0,
        top: cropTop || 0,
      });

      const link = document.createElement("a");
      link.download = `avenuz-awesome-${new Date().getTime()}.png`;
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // done 
      c.set({ backgroundColor: backgroundColor });
      c.exporting = false;
      UtilsEvents.window.dispatch('resize',{})
    
    } catch (error) {

      window.alert("cant download");
      // done
      c.set({ backgroundColor: backgroundColor });
      c.exporting = false;
      UtilsEvents.window.dispatch('resize',{})

    }
  };

  toBase64 = ( c, settings ) => {

    const {width: cropWidth, height: cropHeight, top: cropTop, left: cropLeft, format, quality, multiplier, centralize} = settings || {};

    // init class
    const uc = new UtilsCanvas();
    // save angle zero
    uc.rotateReset( c );
    // zoom to 1:1
    c.setViewportTransform([1,0,0,1,0,0]); 
    // Transforma o stage do canvas no tamanho do CLIP
    const {clipPath} = c;
    const {width, height} = (clipPath && c.clipPath.getBoundingRect()) || c;
    const {backgroundColor} = c;
    // block resize and events
    c.exporting = true;
    // set dimensions    
    c.setWidth(width);
    c.setHeight(height);
    // transparent
    //################################### #ffff onlyto valpec catalog
    c.set({ backgroundColor: '#ffffff', stroke: null, strokeWidth: 0, });
    // center content
    centralize && uc.center( c );
    
    return new Promise(( resolve, reject ) => {

      try {

        console.log(settings);

        const data = c.toDataURL({
          enableRetinaScaling: true,
          // format
          format: format || 'png', // png | jpg
          quality: quality || 1, // only jpg
          // scale
          multiplier: multiplier || 1, // scale, default 1
          // cropped
          width: cropWidth || width,
          height: cropHeight || height,
          left: cropLeft || 0,
          top: cropTop || 0,
        });

        resolve({ data, width, height });
        // done 
        c.set({ backgroundColor: backgroundColor });
        c.exporting = false;
        UtilsEvents.window.dispatch('resize',{});
        // render all
        UtilsCanvas.renderAll(c);
        
      } catch (error) {
        reject( error );
        // done 
        c.set({ backgroundColor: backgroundColor });
        c.exporting = false;
        UtilsEvents.window.dispatch('resize',{});
      }
  
    });
  };

}
