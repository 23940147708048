import { Container } from "@mui/material";
import React from "react";
import stylesImport from '../styles';
import ImageEdition from "./ImageEdition";

export default function CreatePersonalSign(props) {

  return (
    <Container maxWidth="md">
      <h2>Assinatura & Rúbrica</h2>
      <h3>Marcações em contratos</h3>
      <div style={styles.containerPaper}>
        <div>
          <ImageEdition/>
        </div>
      </div>
    </Container>
  );
}

const styles = {
  ...stylesImport,
  colorIcons: '#005F4E',
}