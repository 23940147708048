import React, { useState } from "react";
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Document, Page, pdfjs } from 'react-pdf';
import stylesImport from '../styles';
import { Button, Container, Tooltip, Grid, Alert, FormControlLabel, Radio, RadioGroup, Checkbox, AlertTitle } from "@mui/material";
import Cookies from 'universal-cookie';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const cookies = new Cookies();

export default function ViewPdf () {	

  const [loadDocument, setLoadDocument] = useState('./contracts/contrato-assinado.pdf');

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [signType, setSignType] = React.useState(1);

  const [widthDocument, setWidthDocument] = useState(595); //595); //850);
  const [heightDocument, setHeightDocument] = useState(595); //595); //850);

  const [signPerPage, setSignPerPage] = useState([]);
  const [signPerPageBoolean, setSignPerPageBoolean] = useState([]);
  
  const [disableNextBtn, setDisableNextBtn] = useState(false);
  const [disablePrevBtn, setDisablePrevBtn] = useState(true);
  
  const [posMouseOver, setPosMouseOver] = useState({x: -200, y: -200, left: -200, top: -200,})
  const [posRub, setPosRub] = useState({x: -200, y: -200, left: -200, top: -200,})
  const [widthSign, setWidthSign] = useState(100);

  const [loading, setLoading] = useState(true);
  //const [signShow, setSignShow] = useState(false);
  
  const iconPage = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M14.5 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7.5L14.5 2z"></path>
      <polyline points="14 2 14 8 20 8"></polyline>
    </svg>
  );
  const iconCheck = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M4 22h14a2 2 0 002-2V7.5L14.5 2H6a2 2 0 00-2 2v4"></path>
      <path d="M14 2v6h6"></path>
      <path d="M3 15l2 2 4-4"></path>
    </svg>
  );


  const [error, setError] = React.useState(false);
  
  function onError(err) {
    console.log(err);
    setError(err.message);
  }

  function onDocumentLoadSuccess({ numPages }) {
    console.log('Loaded PDF');
    setNumPages(numPages);
    setLoading(false);
    setSignPerPageBoolean(new Array(numPages).fill(0));
  }

  const prevPage = () => {
    let v = pageNumber; --v;
    if(v <= 1){
      setDisablePrevBtn(true);
      //return;
    }

    setPageNumber(v);
    setDisableNextBtn(false);

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500);

  }

  const nextPage = () => {
    let v = pageNumber; ++v;
    if(v >= numPages){
      setDisableNextBtn(true);
      //return;
    }

    setPageNumber(v);
    setDisablePrevBtn(false);

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500);
  }

  function onLoadProgress(event) {
    console.log(event);
  }

  // pages

  function onLoadError (event) {
    window.alert('Erro em carregar a página do contrato');
    console.log(event);
  }
  function onLoadSuccess (event) {
    console.log();
    setLoading(true);
    if(event?._pageInfo?.view){
      if(event?._pageInfo?.view[3] > 0){
        setHeightDocument(event?._pageInfo?.view[3]);
      }  
    }
  }

  function onRenderError (event) {
    window.alert('Erro em renderizar o pdf');
    console.log(event);
  }
  function onRenderSuccess (event) {
    console.log(event);    
    setLoading(false);
    resizeScreen();
  }

  const PagesAssign = ({data}) => {
    return (
      <Grid
        container
        sx={{ flexGrow: 1, mt: 2, mb: 3 }} 
        spacing={1}
        display="flex"
        direction="row"
        justify="center"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item>
          <Button size="large" type="default" variant="outlined" onClick={prevPage} disabled={disablePrevBtn} >Anterior</Button>
        </Grid>
        <Grid item>
          {pageNumber} / {numPages}
        </Grid>
        <Grid item>
          <Button size="large" type="default" variant="outlined" onClick={nextPage} disabled={disableNextBtn} >Próxima</Button>
        </Grid>
      </Grid>
    );
  }

  let timeResize = 0;
  const resizeScreen = () => {

    //let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
    //document.body.style.zoom = 1.0;
    console.log('zoom', document.body.style.zoom, window.visualViewport.scale);

    const element = document.getElementById('PDFViewer');
    const width = element.clientWidth || element.width || widthDocument || undefined;
    const height = element.clientHeight || element.height || undefined;
    
    const screenWidth = window.innerWidth;
    // const screenHeight = window.innerHeight;
    
    const value = (screenWidth/2 - width/2) - 25;
    console.log(width, screenWidth, value, height);

    if(width + 60 >= window.innerWidth) {
      setWidthDocument(window.innerWidth - 60);
    } else {
      setWidthDocument(width);
    }

    setHeightDocument(height);

    if(width < 200 && timeResize < 50) {
      ++timeResize;
      setTimeout(resizeScreen, 500);
      console.log('resizeScreen');
      return;
    }

  }

  React.useEffect(() => {
    window.addEventListener('resize', resizeScreen);
    return () => {
      window.removeEventListener('resize', resizeScreen);
    }
  },[]);

	return (
    <Container maxWidth="md">

      <Button variant="outlined" startIcon={<HelpOutlineIcon/>} sx={{float: 'right'}} disabled>Ajuda</Button>
      <h2 style={{ marginBottom: 0, padding: 0 }}>Visualização</h2>

      <br/>
      
      <div style={styles.containerPaper}>

      {error ? (
        <React.Fragment>

          <Alert variant="filled" color="error">
            <AlertTitle>Ocorreu um erro no carregamento do arquivo, tente mais tarde. {error} </AlertTitle>
          </Alert>
          <div style={styles.buttons}>
            <Link to={'/sign-documents'}>
              <Button size="large" type="primary" variant="outlined">Voltar</Button>              
            </Link>
          </div>

        </React.Fragment>
      ):(
        <React.Fragment>

          <Alert variant="filled" color="info">
            Contrato Número - 12312 1231213 2
          </Alert>

          <PagesAssign data={signPerPageBoolean} />

          <div id="PDFViewerContent" style={{boxShadow: '2px 2px 12px #AAAAAA77', minHeight: heightDocument + 2, border: '1px solid #cccccc', backgroundColor: '#fff' }}>
            <div id="PDFViewer" style={{clear: 'both', display: (loading ? 'none' : 'block')}}
            >

              <Document
                  file={loadDocument}
                  loading={<div style={{padding: 50, textAlign: 'center'}}>Carregando documento...</div>}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadProgress={onLoadProgress}
                  onSourceError={onError}
                  onError={onError}
                  onLoadError={onError}
                  >
                      {/* {
                        Array.from(
                          new Array(numPages),
                          (el, index) => (
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                            />
                          ),
                        )
                      } */}
                  <Page 
                    width={widthDocument}
                    pageNumber={pageNumber} 
                    loading={<div style={{padding: 50, textAlign: 'center'}}>Carregando página...</div>}

                    onLoadError={onLoadError}
                    onLoadSuccess={onLoadSuccess}
                    onRenderError={onRenderError}
                    onRenderSuccess={onRenderSuccess}

                    />
              </Document>

            </div>
          </div>

          <PagesAssign data={signPerPageBoolean} />

          <div style={styles.buttons}>
            <Link to="/sign-documnents">
              <Button size="large" type="primary" variant="contained" sx={{ mr: 1 }} >Voltar</Button>
            </Link>
            <Button startIcon={<DownloadOutlined/>} disabled size="large" type="primary" variant="outlined" sx={{ mr: 1 }} >Baixar documento</Button>
          </div>

        </React.Fragment>
        )}
      </div>

		</Container>
	);
}

const styles = {
  ...stylesImport
};