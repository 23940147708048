import React, { useState } from "react";
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Document, Page, pdfjs } from 'react-pdf';
import stylesImport from '../styles';
import { Button, Container, Tooltip, Grid, Alert, FormControlLabel, Radio, RadioGroup, Checkbox, AlertTitle } from "@mui/material";
import Cookies from 'universal-cookie';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const cookies = new Cookies();

export default function SignPdf () {	

  const [loadDocument, setLoadDocument] = useState('./contracts/contrato-assinado.pdf');

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [signType, setSignType] = React.useState(1);

  const [marginLeftDocument, setMarginLeftDocument] = useState(0);
  const [widthDocument, setWidthDocument] = useState(595); //595); //850);
  const [heightDocument, setHeightDocument] = useState(595); //595); //850);

  const [signPerPage, setSignPerPage] = useState([]);
  const [signPerPageBoolean, setSignPerPageBoolean] = useState([]);
  const [signComplete, setSignComplete] = useState(false);
  
  const [disableNextBtn, setDisableNextBtn] = useState(false);
  const [disablePrevBtn, setDisablePrevBtn] = useState(true);
  
  const [posMouseOver, setPosMouseOver] = useState({x: -200, y: -200, left: -200, top: -200,})
  const [posRub, setPosRub] = useState({x: -200, y: -200, left: -200, top: -200,})
  const [widthSign, setWidthSign] = useState(100);

  const [loading, setLoading] = useState(true);
  //const [signShow, setSignShow] = useState(false);
  
  const iconPage = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M14.5 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7.5L14.5 2z"></path>
      <polyline points="14 2 14 8 20 8"></polyline>
    </svg>
  );
  const iconCheck = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M4 22h14a2 2 0 002-2V7.5L14.5 2H6a2 2 0 00-2 2v4"></path>
      <path d="M14 2v6h6"></path>
      <path d="M3 15l2 2 4-4"></path>
    </svg>
  );
  // const iconLayers = (
  //   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  //     <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
  //     <polyline points="2 17 12 22 22 17"></polyline>
  //     <polyline points="2 12 12 17 22 12"></polyline>
  //   </svg>
  // );
  
  // const isPageSigned = (data) => {
  //   if(!data) return false;
  //   if(data.click) return true; else return false;
  // };

  const onChangeSignType = e => {
    console.log('radio checked', e.target.value);
    setSignType(e.target.value);
  };

  const checkSignType = (v) => {
    const n = v || pageNumber;
    if(n === numPages){
      setSignType(2);
    } else {
      setSignType(1);
    }
  };

  const colorIcon = (el, index) => {
    
    let checked = el;
    let color = '#BBBBBB';
    let backgroundColor = '#ffffff'
    let boxShadow = undefined;
    let borderColor = '#aaaaaa';
    let height = 50;
    
    if(index+1 === pageNumber){
      color = '#000000';
      backgroundColor = '#EEEEEE';
      boxShadow = '1px 1px 7px #555555CC';
      // borderColor = "#ffffff";
      height = 60;
    } 

    if(checked) color = 'green';

    return {
      width: 50, height,
      color, 
      backgroundColor,
      boxShadow,
      borderColor,
      marginLeft: -1,
    }

  }
  

  const [error, setError] = React.useState(false);
  
  function onError(err) {
    console.log(err);
    setError(err.message);
  }

  function onDocumentLoadSuccess({ numPages }) {
    console.log('Loaded PDF');
    setNumPages(numPages);
    resizeScreen();
    setLoading(false);
    setSignPerPageBoolean(new Array(numPages).fill(0));
  }

  function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
  }

  const restSignPosition = () => {
    setPosRub({x: -200, y: -200, left: -200, top: -200,});
  }

  let elementMouseOver = null;

  const getMouseOverPositions = (event) => {

    elementMouseOver || (elementMouseOver = document.getElementById('PDFViewer'));
    const {clientX, clientY} = event;
    const plus = 5;

    const d = document,
    e = d.documentElement,
    b = d.body;

    const scrollPositionX = window.scrollX || d.scrollLeft || e.scrollLeft || b.scrollLeft || 0;
    const scrollPositionY = window.scrollY || d.scrollTop || e.scrollTop || b.scrollTop || 0;

    const x = clientX - elementMouseOver.offsetLeft + scrollPositionX + plus;
    const y = clientY - elementMouseOver.offsetTop + scrollPositionY + plus;

    setPosMouseOver({x, y, top: elementMouseOver.offsetTop, left: elementMouseOver.offsetLeft});

  };

  const getClickPositions = (event) => {
    return new Promise((resolve, reject) => {
      try {

        const element = document.getElementById('PDFViewer');
        const {clientX, clientY} = event;
        const target = event.target || event.currentTarget || {};
    
        const d = document,
          e = d.documentElement,
          b = d.body;
  
        const scrollPositionX = window.scrollX || d.scrollLeft || e.scrollLeft || b.scrollLeft || 0;
        const scrollPositionY = window.scrollY || d.scrollTop || e.scrollTop || b.scrollTop || 0;
  
        const x = clientX - element.offsetLeft + scrollPositionX;
        const y = clientY - element.offsetTop + scrollPositionY;
  
        const screenWidth = window.screen.width || window.innerWidth || undefined;
        const screenHeight = window.screen.height || window.innerHeight || undefined;

        let widthDoc  = target.width || target.clientWidth || 500;
        let heightDoc = target.height || target.clientHeight || 840;

        if(heightDoc < 840) heightDoc = 800;

        console.log(widthDoc, heightDoc);

        // if(widthDoc + 60 >= window.innerWidth) {
        //   widthDoc = window.innerWidth - 60;
        // }

        // confere a posicao esta foram da margem;
        let signPosX = (x + widthSign) > widthDoc  ? (x - widthSign/2) : x;
        let signPosY = (y + widthSign) > heightDoc ? (y - widthSign/2) : y;
        
        if(pageNumber === numPages && signPosY < heightDoc/3){
          window.alert('Possivemente sua assinatura está no local errado.');
          return;
        }

        const xPercent = round(signPosX/widthDoc, 6);
        const yPercent = round(signPosY/heightDoc, 6);
  
        // console.log( x, y );
        // console.log( width, height );
        // console.log( xPercent, yPercent );
          
        const date = new Date();
        const data = {
          client: {
            name: undefined,
            id: undefined,  
            email: undefined,
            cpf_cpnj: undefined, 
            enrollment: undefined,
          },
          click: {
            page: pageNumber || 0,
            screen: {x: clientX, y: clientY},
            document: {x: signPosX, y: signPosY, top: element.offsetTop, left: element.offsetLeft},
            relativePosition: {x: xPercent, y: yPercent},
          },
          screen: {
            width: screenWidth, 
            height: screenHeight,
          },
          document: {
            name: '',
            width: widthDoc, 
            height: heightDoc, 
            top: element.offsetTop, 
            left: element.offsetLeft,
          },
          time: {
            date: date, timestamp: date.getTime()
          },
          navigator: navigator.userAgent,
        }

        setPosRub({x: signPosX, y: signPosY, top: element.offsetTop, left: element.offsetLeft});
        resolve(data);
  
      } catch (error) {

        reject(error);
        
      }
    });
  };

  async function onItemClick(event) {

    let dataPos, dataGeo = {}

    try {
      dataGeo = await cookies.get('geo', {path: '/',});
    } catch (error) {
      console.log(error);      
    }

    try {
      dataPos = await getClickPositions(event);
    } catch (error) {
      console.log(error);
    }

    const data = {
      ...dataPos, 
      ...dataGeo,
    };

    // console.log(data);
    // console.log(JSON.stringify(data));

    let singPages = signPerPage;
    let singPagesBoolean = signPerPageBoolean;

    if(!singPages.length){
      singPages = new Array(numPages).fill({});
      singPagesBoolean = new Array(numPages).fill(0);
    }
    singPages[pageNumber-1] = data;
    singPagesBoolean[pageNumber-1] = 1;

    setSignPerPage(singPages);
    setSignPerPageBoolean(singPagesBoolean);

    // -------------------------------------- complete | done
    let complete = true;
    singPagesBoolean.forEach(el => {
      if (el === 0) complete = false;
    });
    signComplete !== complete && setSignComplete(complete);
    signComplete && window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    window.signComplete = complete;
    // -------------------------------------- complete | done

    // bottom
    //window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })

    console.log(JSON.stringify(signPerPage));

  }

  let timeResize = 0;
  const resizeScreen = () => {

    //let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
    //document.body.style.zoom = 1.0;
    console.log('zoom', document.body.style.zoom, window.visualViewport.scale);

    const element = document.getElementById('PDFViewer');
    const width = element.clientWidth || element.width || widthDocument || undefined;
    const height = element.clientHeight || element.height || undefined;
    
    const screenWidth = window.innerWidth;
    // const screenHeight = window.innerHeight;
    
    const value = (screenWidth/2 - width/2) - 25;
    console.log(width, screenWidth, value, height);

    if(width + 60 >= window.innerWidth) {
      // setWidthDocument(window.innerWidth - 60);
      setWidthDocument(595);
    }

    setHeightDocument(height);

    if(width < 200 && timeResize < 50) {
      ++timeResize;
      setTimeout(resizeScreen, 500);
      console.log('resizeScreen');
      return;
    }

    setMarginLeftDocument(value);
  }

  const mouseOverSign = () => {
//    setSignShow(true);
  }

  const mouseOutSign = () => {
//    setSignShow(false);
    setPosMouseOver({x: -200, y: -200, left: -200, top: -200,})
  }

  const checkSignPosition = (n) => {
    console.log(n);
    if(n > signPerPage.length){
      restSignPosition();
    } else {
      let click1 = signPerPage[n-1].click || {};
      let click2 = click1.document || false;
      if(!click2) {
        restSignPosition();
      } else {
        // console.log(click2);
        setPosRub(click2);
      };
      // click = signPerPage && signPerPage[n] && signPerPage[n].click && signPerPage[n].document;
      // console.log(click);
    }
  }

  const changePage = (v) => {

    checkSignType(v);
    checkSignPosition(v);
    setPageNumber(v);
    
    setDisablePrevBtn((v <= 1));
    setDisableNextBtn(v >= numPages);
    
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500);
  }

  const prevPage = () => {
    let v = pageNumber; --v;
    if(v <= 1){
      setDisablePrevBtn(true);
      //return;
    }

    checkSignType(v);
    checkSignPosition(v);
    setPageNumber(v);
    setDisableNextBtn(false);

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500);

  }

  const nextPage = () => {
    let v = pageNumber; ++v;
    if(v >= numPages){
      setDisableNextBtn(true);
      //return;
    }

    checkSignType(v);
    checkSignPosition(v);
    setPageNumber(v);
    setDisablePrevBtn(false);

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500);
  }

  React.useEffect(() => {
    //setWidthSign(widthDocument * 0.2);
  },[widthDocument]);

  function onBeforeUnload (e) {

    e = e || window.event;

    // https://www.npmjs.com/package/react-before-leave
    // console.log('window.signComplete', window.signComplete);

    if(!window.signComplete){

      try {
        e.stopPropagation();
        e.preventDefault();          
      } catch (error) {}

      // For IE and Firefox prior to version 4
      e.cancelBubble = true;
      e.returnValue = 'Desejar sair?';

      // For Safari
      return 'Desejar sair?';
    } else {

      e.cancelBubble = true;
      e.returnValue = '';
      delete e['returnValue'];

    }

  };

  function onLoadProgress(event) {
    console.log(event);
  }

  // pages

  function onLoadError (event) {
    window.alert('Erro em carregar a página do contrato');
    console.log(event);
  }
  function onLoadSuccess (event) {
    console.log();
    setLoading(true);
    if(event?._pageInfo?.view){
      if(event?._pageInfo?.view[3] > 0){
        setHeightDocument(event?._pageInfo?.view[3]);
      }  
    }
  }

  function onRenderError (event) {
    window.alert('Erro em renderizar o pdf');
    console.log(event);
  }
  function onRenderSuccess (event) {
    console.log(event);    
    setLoading(false);
  }

  const PagesAssign = ({data}) => {
    return (
      <Grid
        container
        sx={{ flexGrow: 1, mt: 2, mb: 3 }} 
        spacing={1}
        display="flex"
        direction="row"
        justify="center"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item>
          <Button size="large" type="default" variant="outlined" onClick={prevPage} disabled={disablePrevBtn} >Anterior</Button>
        </Grid>
        <Grid item>
          {data && data.map((el, index) => (
            <Tooltip title={`Página ${index+1}`}>
              <span style={{...colorIcon(el, index), boxShadow: undefined, padding: '0px 6px', backgroundColor: undefined, cursor: 'pointer'}}
              onClick={()=>changePage(index+1)}>
                {el ? iconCheck : iconPage}
              </span>
            </Tooltip>
          ))}
        </Grid>
        <Grid item>
          <Button size="large" type="default" variant="outlined" onClick={nextPage} disabled={disableNextBtn} >Próxima</Button>
        </Grid>
      </Grid>
    );
  }

  // pages

  React.useEffect(() => {
    // window.addEventListener('beforeunload', onBeforeUnload, {capture: true});
    window.addEventListener('resize', resizeScreen);
    return () => {
      // window.removeEventListener('beforeunload', onBeforeUnload, {capture: true});
      window.removeEventListener('resize', resizeScreen);
    }
  },[]);

	return (
    <Container maxWidth="md">

      <Button variant="outlined" startIcon={<HelpOutlineIcon/>} sx={{float: 'right'}} disabled>Ajuda</Button>
      <h2 style={{ marginBottom: 0, padding: 0 }}>Contrato on-line</h2>

      {/* <h3>Contrato número: 6752392839890232</h3> */}
      {/* <h3>Veja os itens abaixo para validar o documento</h3>
      <p>
        <ul>
          <li>Leia atentamente o contrato</li>
          <li>Todos as páginas precisam ser rubricadas</li>
          <li>Para rubricar/assinar basta clicar sobre o documento</li>
          <li>Assine no local indicado</li>
          <li>Indique o e-mail da sua testemunha</li>
          <li>Toda assinatura gera uma chave criptografada que corresponde a uma assinatura real</li>
        </ul>
      </p> */}

      <br/>
      
      <div style={styles.containerPaper}>

      {error ? (
        <React.Fragment>

          <Alert variant="filled" color="error">
            <AlertTitle>Ocorreu um erro no carregamento do arquivo, tente mais tarde. {error} </AlertTitle>
          </Alert>
          <div style={styles.buttons}>
            <Link to={'/sign-documents'}>
              <Button size="large" type="primary" variant="outlined">Voltar</Button>              
            </Link>
          </div>

        </React.Fragment>
      ):(
        <React.Fragment>

          <Alert variant="filled" color="info">
            Contrato Número - 12312 1231213 2
          </Alert>

          <PagesAssign data={signPerPageBoolean} />

          <div id="PDFViewerContent" style={{width: widthDocument + 2, minHeight: heightDocument + 2,  margin: '0 auto', boxShadow: '2px 2px 12px #AAAAAA77', border: '1px solid #cccccc', backgroundColor: '#fff' }}>
            <div id="PDFViewer" style={{margin: '0 auto', cursor: 'crosshair', clear: 'both', display: (loading ? 'none' : 'block')}}
              onMouseOver={mouseOverSign} onMouseOut={mouseOutSign} onMouseMove={getMouseOverPositions}
            >

              <Document
                style={{margin: '0 auto', }}
                  file={loadDocument}
                  loading={<div style={{padding: 50, textAlign: 'center'}}>Carregando documento...</div>}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadProgress={onLoadProgress}
                  onSourceError={onError}
                  onError={onError}
                  onLoadError={onError}
                  onItemClick={onItemClick}
                      onClick={onItemClick} >
                      {/* {
                        Array.from(
                          new Array(numPages),
                          (el, index) => (
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                            />
                          ),
                        )
                      } */}
                  <Page 
                    width={widthDocument}
                    pageNumber={pageNumber} 
                    loading={<div style={{padding: 50, textAlign: 'center'}}>Carregando página...</div>}

                    onLoadError={onLoadError}
                    onLoadSuccess={onLoadSuccess}
                    onRenderError={onRenderError}
                    onRenderSuccess={onRenderSuccess}

                    />
              </Document>

              <div style={{
                width: widthSign,
                left: posRub.x + posRub.left, 
                top: posRub.y + posRub.top,
                zIndex: 99999,
                textAlign: 'center', 
                backgroundColor: '#EEEEEE11', 
                position: 'absolute', 
              }}>
                <img src="/sign/aaa.png" style={{width: widthSign}} alt="Sign Client"/>
              </div>

              <div style={{
                width: 100,
                left: posMouseOver.x + posMouseOver.left, 
                top: posMouseOver.y + posMouseOver.top,
                zIndex: 9999,
                textAlign: 'right',
                backgroundColor: '#FFFF00CC',
                border: '1px solid orange', 
                position: 'absolute', 
                padding: '5px 10px',
                fontSize: 10,
              }}>
                Assinar
              </div>

            </div>
          </div>

          <PagesAssign data={signPerPageBoolean} />

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ mb: 2, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}
            value={signType}
            onChange={onChangeSignType}
          >
            <FormControlLabel value={1} control={<Radio />} label="Rúbrica" />
            <FormControlLabel value={2} control={<Radio />} label="Assinatura" />
          </RadioGroup>

          <Alert variant="standard" color="info">
            Rubrique <strong>todas as páginas</strong>
          </Alert>
          <Alert variant="standard" color="info">
            Verifique se a assinatura está <strong>sobre seu nome</strong>
          </Alert>
          <Alert variant="standard" color="info">
            Depois daqui gera um token ou chave enviada para o email para confirmar a assinatura. 10min
          </Alert>
          <Alert variant="standard" color="warning">
            Toda assinatura gera uma <strong>chave criptografada</strong> que corresponde a uma assinatura real
          </Alert>

          <div style={styles.buttons}>
            <Button size="large" type="primary" variant="contained" disabled={!signComplete} >Continuar</Button>
          </div>

        </React.Fragment>
        )}
      </div>

      {signPerPage.length ? signPerPage.map((el, index) => (
        <div>{el ? (el.click ? JSON.stringify(el.click) : 'Ass' ) : index }</div>
      )) : ''}

		</Container>
	);
}

const styles = {
  ...stylesImport
};