const styles = {
  container: {
    // padding: 20,
    /* Center horizontally*/
    margin: '0 auto',
  },
  containerPaper: {
    boxShadow: '2px 2px 20px #ddddddAA',
    maxWidth: 860,
    backgroundColor: 'white',
    padding: 20,
    /* Center horizontally*/
    margin: '0 auto',
  },
  buttons: {
    paddingTop: 20,
    marginTop: 30,
    borderTop: '1px dotted #aaa',
  },
};
export default styles;