import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { Link } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  background: '#eeeeee',
}));

// https://mui.com/components/grid/

export default function DashDocumentSign() {
  return (
    <Box sx={{ width: '100%', marginBottom: '20px' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={4}>
          <Link to="/personal-sign" className="removeHyperlink">
            <Item style={{backgroundColor: '#ffffff'}}>
              <h3 style={{color: 'red'}}>Assinatura</h3>
              <p>
                Registre sua assinatura
              </p>
            </Item>
          </Link>
        </Grid>
        <Grid item xs={4}>
          <Item style={{backgroundColor: '#ffffff'}}>
            <h3 style={{color: 'orange'}}>Aguardando: 1</h3>
            <p>
              Contrato(s) não assinado(s)
            </p>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item style={{backgroundColor: '#ffffff'}}>
            <h3 style={{color: 'green'}}>Assinados: 0</h3>
            <p>
              Contrato(s) assinado(s)
            </p>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}